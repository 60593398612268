<template>
  <div style="background-color:#f0f2f5;padding:0;">
    <a-card :bordered="false" class="p18">
      <DescriptionList title="费用信息" size="large">
        <div style="margin-top: 16px">
          <Description term="出租电费"
            ><label
              >{{ dataQueryCost.elecPrice }}&nbsp;&nbsp;元</label
            ></Description
          >
          <Description term="出租水费"
            ><label
              >{{ dataQueryCost.waterPrice }}&nbsp;&nbsp;元</label
            ></Description
          >
          <Description term="出租燃气费"
            ><label
              >{{ dataQueryCost.gasPrice }}&nbsp;&nbsp;元</label
            ></Description
          >
        </div>
      </DescriptionList>
      <DescriptionList size="large">
        <div style="margin-top: 16px">
          <Description term="是否使用空调"
            ><label>{{ baseInfo.ac == "N" ? "否" : "是" }}</label></Description
          >
          <Description term="是否使用燃气"
            ><label>{{ baseInfo.gas == "N" ? "否" : "是" }}</label></Description
          >
          <Description term="是否使用智能电表"
            ><label>{{
              buildingInfo.smartMeter == "N" ? "否" : "是"
            }}</label></Description
          >
          <Description term="电是否用物业卡充值"
            ><label>{{
              baseInfo.electricPrepaid == "N" ? "否" : "是"
            }}</label></Description
          >
          <Description term="水是否用物业卡充值"
            ><label>{{
              baseInfo.waterPrepaid == "N" ? "否" : "是"
            }}</label></Description
          >
          <Description term="燃气是否用物业卡充值"
            ><label>{{
              baseInfo.gasPrepaid == "N" ? "否" : "是"
            }}</label></Description
          >
        </div>
      </DescriptionList>
    </a-card>
    <a-card :bordered="false" class="p18 mt18">
      <a-table
        rowKey="id"
        :columns="columnsCost"
        :dataSource="dataCost"
        :pagination="paginationCost"
        @change="changePageSizeCost"
        :scroll="{ x: 800 }"
      >
      </a-table>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;

import { defaultPagination } from "@/utils/pagination";

export default {
  name: "CostDetail",
  props: {
    baseInfo: {},
    buildingInfo: {},
    dataQueryCost: {}
  },
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      // 表头
      columnsCost: [
        {
          title: "管理费类型",
          dataIndex: "manageCostTypeName",
          key: "manageCostTypeName",
          fixed: "left"
        },
        {
          title: "费用类型",
          dataIndex: "costTypeName",
          key: "costTypeName"
        },
        {
          title: "金额",
          dataIndex: "amount",
          key: "amount"
        },
        {
          title: "操作人",
          dataIndex: "modifier",
          key: "modifier"
        },
        {
          title: "操作时间",
          dataIndex: "gmtModified",
          key: "gmtModified"
        }
      ],
      // dataCost 表格数据
      dataCost: [],
      // 页码
      paginationCost: defaultPagination(() => {})
    };
  },
  watch: {},
  created() {
    this.manageCostList();
  },
  computed: {},
  methods: {
    moment,
    // 换叶
    changePageSizeCost(val) {
      this.manageCostList(val.current, val.pageSize);
    },
    // 获取管理费列表
    manageCostList(current, pageSize) {
      axios({
        url: "/facility/manageCost/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          facilityId: this.baseInfo.id
        }
      }).then(res => {
        if (res.data.obj) {
          res.data.obj.forEach(item => {
            item.manageCostTypeId = item.manageCostTypeId.toString();
            item.costTypeId = item.costTypeId.toString();
            item.gmtModified = !!item.gmtModified
              ? moment(item.gmtModified).format("YYYY-MM-DD HH:mm:ss")
              : "";
          });
          this.dataCost = res.data.obj;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
.mt18 {
  margin-top: 18px;
}
</style>
