<template>
  <div class="overflow-scroll">
    <div style="min-width: 1225px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
      >
        <a-card
          :bordered="false"
          style="margin-top:20px;"
          class="stallsEditLabelWidth"
        >
          <DescriptionList
            title="档口信息"
            size="large"
            style="margin: -24px 0 -36px 0"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="0" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店编号">
                        <a-input
                          style="width: 240px"
                          disabled
                          v-decorator="[
                            'facilityCode',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.facilityCode
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="16" :sm="24">
                      <a-form-item label="门店名称">
                        <a-input
                          style="width: 607px"
                          disabled
                          v-decorator="[
                            'facilityName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入!'
                                }
                              ],
                              initialValue: this.facilityName
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口编号">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入!'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="招商状态">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'bdStatus',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in bdStatus"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口类型">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'type',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in stallsType"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口建筑等级">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'buildingDTO.engineeringLevel',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in buildLevel"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList title="建筑信息" size="large">
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="0" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口使用面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.usableArea',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口吧台面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.barArea',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口建筑面积">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'buildingDTO.totalArea',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口电量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.actualElectricity',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口排风量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.actualExhaust',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="16" :sm="24">
                    <a-form-item label="档口燃气量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.gas',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24">
                    <a-form-item label="空调配置">
                      <a-input
                        style="width: 607px"
                        v-decorator="[
                          'buildingDTO.airConditioner',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24">
                    <a-form-item label="营业执照图片：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block;margin-bottom: 12px"
                        >
                          <a-upload
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            listType="picture-card"
                            :fileList="fileList"
                            accept=".png,.jpg"
                            @preview="handlePreview"
                            @change="handleChange"
                          >
                            <div v-if="fileList.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="visible"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList
            title="定价信息"
            size="large"
            style="margin-bottom: -48px"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="0" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="基础服务费底价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'minRent',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="基础服务费标价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'retailRent',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="入会费底价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'entryFeeMin',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="入会费标价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'entryFeeRetailPrice',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="16" :sm="24">
                      <a-form-item label="管理费定价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'managerRent',
                            {
                              rules: [
                                {
                                  pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                  required: true,
                                  message: '请输入'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px;margin-bottom:20px;">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="addNewCancel">
              取消
            </a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="submitInfo"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "ant-design-vue";
import DescriptionList from "@/components/DescriptionList";
import { Divider } from "ant-design-vue";
  
import { stallsType } from "@/objects/businessObjects";

export default {
  name: "storeListDetailNewStalls",
  props: ["thisDetailId", "facilityName", "facilityCode"],
  components: { DescriptionList, Divider },
  data() {
    return {
      //  图片弹窗
      visible: false,
      // 图片预览URL
      previewImage: "",
      // 图片上传列表
      fileList: [],
      // 样式Tab固态属性
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      noTitleKey: "baseDetail",
      // 格式化form
      form: this.$form.createForm(this),
      //档口类型
      stallsType: stallsType,
      //档口建筑等级
      buildLevel: [
        {
          id: "L0",
          name: "L0"
        },
        {
          id: "L1",
          name: "L1"
        },
        {
          id: "L2",
          name: "L2"
        },
        {
          id: "L3",
          name: "L3"
        },
        {
          id: "L4",
          name: "L4"
        }
      ],
      //招商状态
      bdStatus: [
        {
          id: 1,
          name: "可招商"
        },
        {
          id: 2,
          name: "不可招商"
        }
      ],
      //客户状态
      tenantStatus: [
        {
          id: 1,
          name: "预定中"
        },
        {
          id: 2,
          name: "已预订"
        },
        {
          id: 3,
          name: "签约中"
        },
        {
          id: 4,
          name: "已签约"
        },
        {
          id: 5,
          name: "改造中"
        },
        {
          id: 6,
          name: "待入驻"
        },
        {
          id: 7,
          name: "已入驻"
        },
        {
          id: 8,
          name: "申请退租中"
        },
        {
          id: 9,
          name: "退租中"
        },
        {
          id: 10,
          name: "已退租"
        },
        {
          id: 11,
          name: "已离场"
        }
      ],
      //厨房品牌
      brandNameList: [
        { id: 1, name: "吉刻联盟" },
        { id: 2, name: "闪电厨房" },
        { id: 3, name: "食秀坊" },
        { id: 4, name: "橘猫" },
        { id: 5, name: "海星微厨" },
        { id: 6, name: "美食门" }
      ]
    };
  },
  created() {},
  methods: {
    // 显示图片
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
      this.edit = false;
    },
    //  上传函数
    handleChange({ fileList }) {
      fileList.forEach((item, index) => {
        if (item.status == "error") {
          fileList.splice(index, 1);
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList = fileList;
    },
    // 获取上传图片地址
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    // 发送取消函数
    addNewCancel() {
      this.$emit("cancelAddStalls");
    },
    // 发送信息
    submitInfo() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          modal.confirm({
            title: "是否需要保存?",
            onOk() {
              that.addNewStall(values);
            },
            onCancel() {}
          });
        }
      });
    },
    //新建档口
    addNewStall(values) {
      axios({
        url:   this.$baseUrl + "/kitchen/create",
        method: "POST",
        withCredentials: true,
        data: {
          ...values,
          facilityId: this.thisDetailId
        }
      }).then(res => {
        this.$emit("cancelAddStalls");
      });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
  /*margin-top: 24px;*/
}
</style>
