<template>
  <div style="background-color:#f0f2f5;padding:0;">
    <a-card :bordered="false" class="p18">
      <DescriptionList
        title="联系方式"
        size="large"
        style="margin-bottom: 32px"
      >
        <div style="margin-top: 16px">
          <Description term="业主/房东主要联系人"
            ><label>{{ baseInfo.landLoad }}</label></Description
          >
          <Description term="业主/房东财务联系人"
            ><label>{{ baseInfo.financeManager }}</label></Description
          >
          <Description term="业主/房东物业管理联系人"
            ><label>{{ baseInfo.propertyManager }}</label></Description
          >
          <Description term="业主/房东主要联系人电话"
            ><label>{{ baseInfo.landLoadPhone }}</label></Description
          >
          <Description term="业主/房东财务电话"
            ><label>{{ baseInfo.financePhone }}</label></Description
          >
          <Description term="业主/房东物业管理人电话"
            ><label>{{ baseInfo.pmPhone }}</label></Description
          >
        </div>
      </DescriptionList>
      <a-divider />
      <DescriptionList title="承租合同信息" size="large">
        <div style="margin-top: 16px">
          <Description term="是否有转租合同"
            ><label>{{ baseInfo.subleaseAuth }}</label></Description
          >
          <Description term="承租合同开始日期"
            ><label>{{
              changeTimeStyle(baseInfo.lesseeStartDateLg)
            }}</label></Description
          >
          <Description term="承租合同结束日期"
            ><label>{{
              changeTimeStyle(baseInfo.lesseeEndDateLg)
            }}</label></Description
          >
        </div>
      </DescriptionList>
      <a-divider />
      <DescriptionList title="费用信息" size="large">
        <div style="margin-top: 16px">
          <Description term="承租电费"
            ><label
              >{{ baseInfo.lesseeElecPrice }}&nbsp;&nbsp;元/度</label
            ></Description
          >
          <Description term="承租水费"
            ><label
              >{{ baseInfo.lesseeWaterPrice }}&nbsp;&nbsp;元/吨</label
            ></Description
          >
          <Description term="承租燃气"
            ><label
              >{{ baseInfo.lesseeGasPrice }}&nbsp;&nbsp;元/m³</label
            ></Description
          >
        </div>
      </DescriptionList>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;

export default {
  name: "RentDetail",
  props: {
    baseInfo: {}
  },
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    moment,
    // 返回日期格式
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
</style>
