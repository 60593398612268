<template>
  <div>
    <div>
      <!-- 头部 -->
      <div style="margin: -45px -24px 0px">
        <a-card :bordered="false">
          <DescriptionList>
            <div style="text-align: right" class="table-operatorTitle">
              <label class="detailTop-edit">
                <img :src="this.changeFirstImg()" class="icon" />
                &nbsp;&nbsp;{{ baseInfo.name }}&nbsp;&nbsp;
                <div :class="this.changeImgbg()">{{ this.changeStatue }}</div>
                &nbsp;&nbsp;
               <a-button
                  @click="changeStoreStatus"
                  v-if="this.downHasRight == true"
                  style="font-size: 0.5em; margin-left: 1.5em"
                  >状态变更</a-button
                >
              </label>
              <a-button type="primary" @click="goInfoEdit">编辑</a-button>
            </div>
            <div style="margin: 36px; width: 90%">
              <Description term="总档口数" class="first">
                <label>{{ baseInfo.kitchenCount }}</label>
              </Description>
              <Description term="可招商档口数量" class="first">
                <label>{{ baseInfo.emptyKitchenCount }}</label>
              </Description>
              <Description term="计租率" class="first">
                <label>{{ baseInfo.contractFullRate }}</label>
              </Description>
              <Description term="招商状态" class="first">
                <label>{{ getSalesStatus(baseInfo.salesStatus) }}</label>
              </Description>
              <Description term="所属厨房">
                <label>{{ baseInfo.facilityBrand | showFacilityBrand }}</label>
              </Description>
              <Description term="门店类型">
                <label>{{ baseInfo.type | showType }}</label>
              </Description>
              <Description term="门店性质">
                <label>{{ baseInfo.attributeText }}</label>
              </Description>
              <Description term="城市">
                <label>{{ baseInfo.cityName }}</label>
              </Description>
              <Description term="门店地址">
                <label>{{ baseInfo.address }}</label>
              </Description>
              <Description term="门店楼层">
                <label>{{ baseInfo.floor }}</label>
              </Description>
              <Description term="取餐往返时间(秒)">
                <label>{{ baseInfo.roundtripTime }}</label>
              </Description>
              <Description term="项目介绍" style="width: 100%">
                <label>{{ baseInfo.description }}</label>
              </Description>
              <Description style="width: 100%">
                <div class="imgBox">
                  <img
                    v-for="(item, index) in mediaUrl.facilityMedias.filter(
                      (item) => item.fType == 'img'
                    )"
                    :key="`img${index}`"
                    slot="cover"
                    alt="example"
                    @click="viewImg(item.url)"
                    :src="item.url"
                  />
                  <div
                    v-for="(item, index) in mediaUrl.facilityMedias.filter(
                      (item) => item.fType == 'pdf'
                    )"
                    :key="`pdf${index}`"
                    class="div-item"
                  >
                    <div @click="viewImg(item.url)" class="embed-cover"></div>
                    <embed
                      :src="`${item.url}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`"
                      class="embed-item"
                    />
                  </div>
                  <div
                    v-for="(item, index) in mediaUrl.facilityMedias.filter(
                      (item) => item.fType == 'other'
                    )"
                    :key="`other${index}`"
                    class="other"
                  >
                    <a target="_blank" :href="item.url" :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </div>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <!-- tab切换 -->
      <div style="margin: -24px -24px 24px">
        <a-card
          style="width: 100%"
          :bordered="false"
          :tabList="tabListNoTitle"
          :activeTabKey="noTitleKey"
          @tabChange="(key) => onTabChange(key, 'noTitleKey')"
        ></a-card>
      </div>
      <!-- 展示内容 -->
      <a-card
        class="tabItem"
        v-if="noTitleKey === 'baseDetail'"
        :bordered="false"
      >
        <BaseDetail
          :stallsnewHasRight="this.stallsnewHasRight"
          :stallsviewHasRight="this.stallsviewHasRight"
          :stallseditHasRight="this.stallseditHasRight"
          :queryInfo="this.queryInfo"
          :baseInfo="this.baseInfo"
          :buildingInfo="this.buildingInfo"
          :floorPlanMedias="this.mediaUrl.floorPlanMedias"
          :designRenderMedias="this.mediaUrl.designRenderMedias"
          :propertyFloorPlan="this.mediaUrl.propertyFloorPlan"
        ></BaseDetail>
      </a-card>
      <a-card
        class="tabItem"
        v-if="noTitleKey === 'rentDetail'"
        :bordered="false"
      >
        <RentDetail :baseInfo="this.baseInfo"></RentDetail>
      </a-card>
      <a-card
        class="tabItem"
        v-if="noTitleKey === 'licenceDetail'"
        :bordered="false"
      >
        <LicenceDetail
          :baseInfo="this.baseInfo"
          :foodCertificateMedias="this.mediaUrl.foodCertificateMedias"
          :bizLicenseMedias="this.mediaUrl.bizLicenseMedias"
          :fsbMedias="this.mediaUrl.fsbMedias"
          :fdaFloorPlanMedias="this.mediaUrl.fdaFloorPlanMedias"
          :completionMediaUrls="this.mediaUrl.completionMediaUrls"
          :fsbSecurityCheckPhoto="this.mediaUrl.fsbSecurityCheckPhoto"
          :otherMediaUrls="this.mediaUrl.otherMediaUrls"
        ></LicenceDetail>
      </a-card>
      <a-card
        class="tabItem"
        v-if="noTitleKey === 'costDetail'"
        :bordered="false"
      >
        <CostDetail
          :baseInfo="this.baseInfo"
          :buildingInfo="this.buildingInfo"
          :dataQueryCost="this.dataQueryCost"
        ></CostDetail>
      </a-card>
    </div>
    <!-- 编辑弹窗 -->
    <a-modal
      title="状态变更"
      :width="360"
      :visible="visiblaReset"
      destroyOnClose
      :confirmLoading="confirmLoading"
      @ok="saveChangeStatus"
      @cancel="changeStatusCancel"
    >
      <a-form :form="form">
        <a-form-item label="门店状态">
          <a-select
            style="width: 240px"
            v-decorator="[
              'status',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
                initialValue: baseInfo.status,
              },
            ]"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in storeStatus"
              :key="item.id"
              placeholder="请选择"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="招商状态">
          <a-select
            style="width: 240px"
            v-decorator="[
              'salesStatus',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
                initialValue: baseInfo.salesStatus,
              },
            ]"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in salesMainStatusList"
              :key="item.id"
              placeholder="请选择"
              >{{ item.value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item class="backName" label="状态变更理由">
          <a-textarea
            style="width: 240px; border-radius: 0 !important"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <ImagePreviewModal
      v-if="visibleImg"
      :imageUrls="showImgUrl"
      :closeAction="hideImageModal"
    />
  </div>
</template>

<script>
import begin from "@/assets/begin.png";
import close from "@/assets/close.png";
import warn from "@/assets/warn.png";
import tenant from "@/assets/tenant.png";

import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
import ImagePreviewModal from "@/components/ImagePreviewModal";
import BaseDetail from "./components/BaseDetail";
import RentDetail from "./components/RentDetail";
import LicenceDetail from "./components/LicenceDetail";
import CostDetail from "./components/CostDetail";

import { mapGetters } from "vuex";
import { hasPermissions } from "@/utils/auth";
import { FacilityActions } from "@/utils/actions";

const { Description } = DescriptionList;

import Bus from "@/bus";
export default {
  name: "storeListDetailNew",
  components: {
    DescriptionList,
    ImagePreviewModal,
    Description,
    BaseDetail,
    RentDetail,
    LicenceDetail,
    CostDetail,
  },
  data() {
    return {
      // 弹窗
      showImgUrl: "",
      visibleImg: false,
      // 路由获取
      queryInfo: {
        id: this.$route.query.thisDetailId,
      },
      // 基本资料获取
      baseInfo: {},
      // 建筑信息
      buildingInfo: {},
      changeStatue: "",
      // 状态变更弹窗
      visiblaReset: false,
      confirmLoading: false,
      // 权限字段
      downHasRight: 1,
      //门店状态
      storeStatus: [
        {
          id: 1,
          name: "已签约",
        },
        {
          id: 2,
          name: "施工中",
        },
        {
          id: 3,
          name: "已开业",
        },
        {
          id: 4,
          name: "停业中",
        },
        {
          id: 5,
          name: "已关店",
        },
      ],
      // salesStatus
      salesMainStatusList: [
        { id: 1, value: "未招商" },
        { id: 2, value: "预招商" },
        { id: 3, value: "招商中" },
        { id: 4, value: "停止招商" },
        { id: 5, value: "暂停招商" },
        { id: 6, value: "已关闭" },
      ],
      // 表格提交
      form: this.$form.createForm(this),
      // 选择tab
      noTitleKey: "baseDetail",
      // 照片对象
      // facilityMedias 头部卡片资料 -- 未开展
      // floorPlanMedias 工程平面图
      // designRenderMedias 设计渲染图
      // foodCertificateMedias 食品经营许可证图片 -- 未开展
      // bizLicenseMedias 营业执照图片 -- 未开展
      // fsbMedias 消防许可证图片 -- 未开展
      // fdaFloorPlanMedias 食药监平面图图片 -- 未开展
      mediaUrl: {
        facilityMedias: [],
        floorPlanMedias: [],
        designRenderMedias: [],
        foodCertificateMedias: [],
        bizLicenseMedias: [],
        fsbMedias: [],
        fdaFloorPlanMedias: [],
        completionMediaUrls: [],
        fsbSecurityCheckPhoto: [],
        otherMediaUrls: [],
        propertyFloorPlan: [],
      },
      // 费用信息
      dataQueryCost: {},
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    stallsnewHasRight() {
      return hasPermissions(
        [FacilityActions.KITCHEN_CREATE],
        this.authenticatedUser.actions
      );
    },
    stallsviewHasRight() {
      return hasPermissions(
        [FacilityActions.KITCHEN_READ],
        this.authenticatedUser.actions
      );
    },
    stallseditHasRight() {
      return hasPermissions(
        [FacilityActions.KITCHEN_EDIT],
        this.authenticatedUser.actions
      );
    },
    tabListNoTitle() {
      const tabs = [
        {
          key: "baseDetail",
          tab: "基本信息",
        },
      ];

      if (
        hasPermissions(
          [FacilityActions.PROPERTY_LEASE_READ],
          this.authenticatedUser.actions
        )
      ) {
        tabs.push({
          key: "rentDetail",
          tab: "承租信息",
        });
      }

      if (
        hasPermissions(
          [FacilityActions.PERMIT_READ],
          this.authenticatedUser.actions
        )
      ) {
        tabs.push({
          key: "licenceDetail",
          tab: "证照信息",
        });
      }

      if (
        hasPermissions(
          [FacilityActions.OPEX_BILL_READ],
          this.authenticatedUser.actions
        )
      ) {
        tabs.push({
          key: "costDetail",
          tab: "费用信息",
        });
      }
      return tabs;
    },
  },
  filters: {
    showType(val) {
      switch (val) {
        case 1:
          return "纯外卖";
          break;
        case 2:
          return "外卖+堂食";
          break;
        case 3:
          return "外卖+大餐厅商户";
          break;
        case 4:
          return "外卖+高端食堂";
          break;
        case 5:
          return "外卖+食堂";
          break;
        case 6:
          return "其他";
          break;
      }
    },
    showFacilityBrand(val) {
      switch (val) {
        case 1:
          return "吉刻联盟";
          break;
        case 2:
          return "闪电厨房";
          break;
        case 3:
          return "食秀坊";
          break;
        case 4:
          return "橘猫";
          break;
        case 5:
          return "海星微厨";
          break;
        case 6:
          return "星选闪电";
          break;
      }
    },
  },
  methods: {
    // 工具
    // 获取招商状态
    getSalesStatus(id) {
      if (!id) {
        return;
      }
      let status = "";
      this.salesMainStatusList.forEach((item) => {
        if (item.id == id) {
          status = item.value;
        }
      });
      return status;
    },
    // pushType
    imgPushType(arr) {
      if (!arr) {
        return [];
      }
      let that = this;
      let aa = [];
      arr.forEach((item) => {
        let type = that.checkURLType(item);
        aa.push({
          url: item,
          fType: type,
        });
      });
      return aa;
    },
    // 判断图片
    checkURLType(item) {
      if (/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(item)) {
        return "img";
      } else if (/\.pdf$/.test(item)) {
        return "pdf";
      } else {
        return "other";
      }
    },
    // 返回日期格式
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    // 查看照片
    viewImg(url) {
      this.showImgUrl = url || "";
      this.visibleImg = true;
    },
    // 更改图标
    changeFirstImg() {
      // 门店状态 1:已签约,2:施工中,3:已开业,4:停业中,5:已关店
      if (this.baseInfo.status == 1 || this.baseInfo.status == 2) {
        return warn;
      } else if (this.baseInfo.status == 3) {
        return begin;
      } else if (this.baseInfo.status == 4 || this.baseInfo.status == 5) {
        return close;
      } else {
        return close;
      }
    },
    changeImgbg() {
      if (this.baseInfo.status == 1 || this.baseInfo.status == 2) {
        return "abuilding";
      } else if (this.baseInfo.status == 3) {
        return "open";
      } else if (this.baseInfo.status == 4 || this.baseInfo.status == 5) {
        return "close";
      } else {
        return "tenant";
      }
    },
    //编辑
    goInfoEdit() {
      this.$router.push({
        path: "/store/storeList/storeListEdit",
        query: { editDetailId: this.queryInfo.id },
      });
    },
    // 状态变更弹窗
    changeStoreStatus() {
      this.visiblaReset = true;
    },
    changeStatusCancel() {
      this.visiblaReset = false;
    },
    // 确认变更状态
    saveChangeStatus() {
      const {
        form: { validateFields },
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          axios({
            url: "facility/updateStatus",
            method: "GET",
            params: {
              facilityId: this.queryInfo.id,
              ...values,
            },
          }).then((res) => {
            if (res.data.success) {
              this.init();
              this.$message.success("状态变更成功！");
              this.visiblaReset = false;
            } else {
              this.$message.error(`${res.data.returnMsg}`);
            }
          });
        }
      });
    },
    // 切换tab
    onTabChange(key, type) {
      this[type] = key;
    },
    // 初始化
    init() {
      this.getBaseInfo();
      this.getBuildingInfo();
      this.queryCost();
    },
    // 头部信息查询
    getBaseInfo() {
      axios({
        url: "/facility/query",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.queryInfo.id,
        },
      }).then((res) => {
        if (res.data.obj) {
          this.baseInfo = res.data.obj;
          if (this.baseInfo.status) {
            this.storeStatus.forEach((item) => {
              if (item.id == this.baseInfo.status) {
                this.changeStatue = item.name;
              }
            });
          }
          // 门店媒体信息"
          this.mediaUrl.facilityMedias =
            this.imgPushType(this.baseInfo.facilityMediaUrls) || [];
          // "营业执照图片"
          this.mediaUrl.bizLicenseMedias =
            this.imgPushType(this.baseInfo.bizLicensePhoto) || [];
          // "食品经营许可证照片"
          this.mediaUrl.foodCertificateMedias =
            this.imgPushType(this.baseInfo.foodCertificatePhoto) || [];
          // "消防验收意见书"
          this.mediaUrl.fsbMedias =
            this.imgPushType(this.baseInfo.fsbPhoto) || [];
          // "消防安全检查合格证"
          this.mediaUrl.fsbSecurityCheckPhoto =
            this.imgPushType(this.baseInfo.fsbSecurityCheckPhoto) || [];
        }
      });
    },
    // 头部建筑信息查询
    getBuildingInfo() {
      axios({
        url: "facility/query/building",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.queryInfo.id,
        },
      }).then((res) => {
        if (res.data.obj) {
          res.data.obj.constructionEndDateLg = this.changeTimeStyle(
            res.data.obj.constructionEndDateLg
          );
          res.data.obj.constructionStartDateLg = this.changeTimeStyle(
            res.data.obj.constructionStartDateLg
          );
          this.buildingInfo = res.data.obj;
          // 食药监平面图图片"
          this.mediaUrl.fdaFloorPlanMedias = this.imgPushType(
            this.buildingInfo.fdaFloorPlanMediaUrls
          );
          // "工程平面图"
          this.mediaUrl.floorPlanMedias = this.imgPushType(
            this.buildingInfo.facilityFloorPlanMediaUrls
          );
          // "门店3D渲染图"
          this.mediaUrl.designRenderMedias = this.imgPushType(
            this.buildingInfo.facilityDesignRenderMediaUrls
          );
          // "竣工验收备案"
          this.mediaUrl.completionMediaUrls = this.imgPushType(
            this.buildingInfo.completionMediaUrls
          );
          // "竣工验收备案"
          this.mediaUrl.otherMediaUrls = this.imgPushType(
            this.buildingInfo.otherMediaUrls
          );
          // 场地总平面图
          this.mediaUrl.propertyFloorPlan = this.imgPushType(
            this.buildingInfo.propertyFloorPlan
          );
        }
      });
    },
    //费用信息
    queryCost() {
      axios({
        url: "/facility/query/cost",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.queryInfo.id,
        },
      }).then((res) => {
        if (res.data.obj) {
          this.dataQueryCost = res.data.obj;
        }
      });
    },
    hideImageModal() {
      this.visibleImg = false;
    },
  },
};
</script>
<style lang="less">
.tabItem .ant-card-body {
  padding: 0 !important;
}
.imgBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  img {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    margin: 1em;
    width: 6em;
    height: 6em;
  }
  .image-edit {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    width: 6em;
    height: 6em;
    margin: 0;
  }
  .embed-item {
    width: 100%;
    height: 100%;
  }
  .embed-cover {
    width: 6em;
    height: 6em;
    position: absolute;
    z-index: 997;
    background: rgba(0, 0, 0, 0); // 透明
  }
  .div-item {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    margin: 1em;
    width: 6em;
    height: 6em;
  }
  .other {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    margin: 1em;
    text-align: center;
    line-height: 6em;
    width: 6em;
    height: 6em;
  }
  img,
  .uploadBox {
    width: 6em;
    height: 6em;
  }
  .uploadBox .ant-upload {
    width: 100%;
    height: 100%;
  }
}
</style>
<style scoped lang="less">
.first {
  font-weight: bolder;
}
</style>
