<template>
  <div style="background-color:#f0f2f5;padding:0;">
    <!-- 门店信息伸缩栏 -->
    <a-collapse accordion :bordered="false" defaultActiveKey="1">
      <a-collapse-panel header="门店信息" key="1" style="border: 0;">
        <DescriptionList size="large">
          <div>
            <Description term="首次开业日期"
              ><label>{{
                this.changeTimeStyle(baseInfo.openDateLg)
              }}</label></Description
            >
            <Description term="营业时间" style="width: 60%"
              ><label
                >{{ this.showTimeStyle(baseInfo.businessHourStartLg) }}—{{
                  this.showTimeStyle(baseInfo.businessHourEndLg)
                }}</label
              ></Description
            >
            <Description term="大楼类型"
              ><label>{{ baseInfo.buildingType }}</label>
            </Description>
            <Description term="周边类型"
              ><label>{{ baseInfo.neighborhoodType }}</label>
            </Description>
            <Description term="是否沿街店铺"
              ><label>{{ baseInfo.streetAccess }}</label>
            </Description>
            <Description term="是否有扶梯"
              ><label>{{ baseInfo.escalatorAccess }}</label>
            </Description>
            <Description term="是否有电梯"
              ><label>{{ baseInfo.elevatorAccess }}</label>
            </Description>
            <Description term="是否有楼梯"
              ><label>{{ baseInfo.stairsAccess }}</label>
            </Description>
            <Description term="地产负责人"
              ><label>{{ buildingInfo.acquisitionsOwner }}</label>
            </Description>
            <Description term="邮政编码"
              ><label>{{ baseInfo.postCode }}</label>
            </Description>
            <Description term="场地合同面积"
              ><label>{{ buildingInfo.buildingGrossArea }}m³</label>
            </Description>
            <Description style="width: 100%" term="营业执照范围"
              ><label
                v-for="(item, index) in baseInfo.bizLicenseScopeIdList"
                :key="index"
                style="margin-right:10px"
                >{{ item | showbizLicenseScopeIdList }}</label
              ></Description
            >
            <Description style="width: 100%" term="食品经营许可证范围"
              ><label
                v-for="(item, index) in baseInfo.cateringLicenseScopeIdList"
                :key="index"
                style="margin-right:10px"
                >{{ item | showCateringLicenseScopeIdList }}</label
              ></Description
            >
          </div>
        </DescriptionList>
        <DescriptionList size="large">
          <a-card class="p18" :bordered="false">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 8">
                <a-col :md="24" :sm="24">
                  <a-form-item label="场地总平面图">
                    <div class="imgBox">
                      <img
                        v-for="(item, index) in propertyFloorPlan.filter(
                          item => item.fType == 'img'
                        )"
                        :key="index"
                        style="width:6em;height:6em;"
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                    </div>
                    <div class="otherBox">
                      <a
                        v-for="(item, index) in propertyFloorPlan.filter(
                          item => item.fType == 'other'
                        )"
                        :key="index"
                        target="_blank"
                        :href="item.url"
                        :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-card>
        </DescriptionList>
      </a-collapse-panel>
    </a-collapse>
    <!-- 建筑信息伸缩栏 -->
    <a-collapse accordion :bordered="false" defaultActiveKey="1" class="mt18">
      <a-collapse-panel header="建筑信息" key="1" style="border: 0;">
        <DescriptionList size="large">
          <div>
            <Description term="门店设计面积"
              ><label>{{ buildingInfo.nfa }}&nbsp;&nbsp;㎡</label></Description
            >
            <Description term="档口可出租总面积" style="width: 60%"
              ><label
                >{{ buildingInfo.totalKitchenUsableArea }}&nbsp;&nbsp;㎡</label
              ></Description
            >
            <a-divider />
            <Description term="门店电量扩容费" style="width: 25%"
              ><label
                >{{
                  buildingInfo.facilityElectricityUpgradeCost
                }}&nbsp;&nbsp;元</label
              ></Description
            >
            <Description term="门店排烟扩容费" style="width: 25%"
              ><label
                >{{
                  buildingInfo.facilityExhaustUpgradeCost
                }}&nbsp;&nbsp;元</label
              ></Description
            >
            <Description term="门店燃气扩容费" style="width: 25%"
              ><label
                >{{ buildingInfo.facilityGasUpgradeCost }}&nbsp;&nbsp;元</label
              ></Description
            >
            <Description term="门店空调扩容费用" style="width: 25%"
              ><label
                >{{ buildingInfo.facilityAcUpgradeCost }}&nbsp;&nbsp;元</label
              ></Description
            >
            <a-divider />
            <Description term="门店扩容前电费总量"
              ><label
                >{{
                  buildingInfo.facilityExistingElectricity
                }}&nbsp;&nbsp;Kw</label
              ></Description
            >
            <Description term="门店扩容前排烟量"
              ><label
                >{{
                  buildingInfo.facilityExistingExhaust
                }}&nbsp;&nbsp;㎡/h</label
              ></Description
            >
            <Description term="门店扩容前燃气量"
              ><label
                >{{ buildingInfo.facilityExistingGas }}&nbsp;&nbsp;㎡</label
              ></Description
            >
            <Description term="门店扩容前空调量"
              ><label
                >{{ buildingInfo.facilityExistingAc }}&nbsp;&nbsp;W</label
              ></Description
            >
            <Description term="门店扩容后电费总量"
              ><label
                >{{
                  buildingInfo.facilityPostUpgradeElectricity
                }}&nbsp;&nbsp;Kw</label
              ></Description
            >
            <Description term="门店扩容后排烟量"
              ><label
                >{{
                  buildingInfo.facilityPostUpgradeExhaust
                }}&nbsp;&nbsp;㎡/h</label
              ></Description
            >
            <Description term="门店扩容后燃气量"
              ><label
                >{{ buildingInfo.facilityPostUpgradeGas }}&nbsp;&nbsp;㎡</label
              ></Description
            >
            <Description term="门店扩容后空调量"
              ><label
                >{{ buildingInfo.facilityPostUpgradeAc }}&nbsp;&nbsp;W</label
              ></Description
            >
          </div>
        </DescriptionList>
        <a-divider />
        <DescriptionList size="large">
          <a-card class="p18" :bordered="false">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 8">
                <a-col :md="12" :sm="24">
                  <a-form-item label="平面规划图">
                    <div class="imgBox">
                      <img
                        v-for="(item, index) in floorPlanMedias.filter(
                          item => item.fType == 'img'
                        )"
                        :key="index"
                        style="width:6em;height:6em;"
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                    </div>
                    <div class="otherBox">
                      <a
                        v-for="(item, index) in floorPlanMedias.filter(
                          item => item.fType == 'other'
                        )"
                        :key="index"
                        target="_blank"
                        :href="item.url"
                        :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="门店3D渲染图">
                    <div class="imgBox">
                      <img
                        v-for="(item, index) in designRenderMedias.filter(
                          item => item.fType == 'img'
                        )"
                        :key="index"
                        style="width:6em;height:6em;"
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                    </div>
                    <div class="otherBox">
                      <a
                        v-for="(item, index) in designRenderMedias.filter(
                          item => item.fType == 'other'
                        )"
                        :key="index"
                        target="_blank"
                        :href="item.url"
                        :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-card>
        </DescriptionList>
      </a-collapse-panel>
    </a-collapse>
    <!-- 人员信息 -->
    <a-card class="p18 mt18">
      <div class="fbt mb18">
        <label><h4>人员信息</h4></label>
        <a-input-search
          v-model="queryParamStaff.codeOrName"
          placeholder="请输入"
          style="width: 200px"
          @search="onSearchStaff"
        />
      </div>
      <a-table
        rowKey="id"
        :columns="staffColumns"
        :dataSource="staffData"
        :pagination="false"
        :scroll="{ x: 800 }"
      >
      </a-table>
    </a-card>
    <!-- 档口列表 -->
    <a-card class="p18 mt18">
      <div class="fbt mb18">
        <label><h4>档口列表</h4></label>
        <div>
          <a-input-search
            v-model="queryParamStall.codeOrName"
            placeholder="请输入档口编号或店铺名称"
            style="width: 220px"
            @search="onSearchStall"
          />
          <a-button
            type="primary"
            icon="plus"
            @click="addStalls"
            class="ml18"
            v-if="this.stallsnewHasRight == true"
            >新增档口
          </a-button>
        </div>
      </div>
      <a-table
        rowKey="id"
        :pagination="false"
        :columns="stallsColumns"
        :dataSource="stallsData"
        :scroll="{ x: 1300 }"

      >
        <span slot="salesStatus" slot-scope="text, record">
          {{ getSalesStatus(record.salesStatus) }}
        </span>
        <span
          slot="bdStatus"
          slot-scope="text, record"
          :class="record.bdStatus == '可招商' ? 'green' : 'red'"
        >
          {{ record.bdStatus }}
        </span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <template>
            <a v-if="stallsviewHasRight == true" @click="handleDetail(record)"
              >查看</a
            >&nbsp;&nbsp;
            <a v-if="stallseditHasRight == true" @click="handleEdit(record)"
              >编辑</a
            >&nbsp;&nbsp;
            <a
              v-if="stallseditHasRight == true"
              @click="handleDeleteStalls(record)"
              >删除</a>
          </template>
        </span>
      </a-table>
    </a-card>
    <!-- 弹窗 -->
    <a-modal :width="1000" v-model="visibleImg" title="图片详情" :footer="null">
      <img style="width:100%;height:100%;" :src="showImgUrl" />
    </a-modal>
    <!-- 新增弹窗 -->
    <a-modal width="95%" v-model="showAddStalls" :footer="null">
      <NewStalls
        :thisDetailId="this.baseInfo.id"
        :facilityCode="this.baseInfo.code"
        :facilityName="this.baseInfo.name"
        @cancelAddStalls="cancelAddStalls"
      ></NewStalls>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
import NewStalls from "./NewStalls";
import { Modal } from "ant-design-vue";
import { defaultPagination } from "@/utils/pagination";
  
import { stallsType } from "@/objects/businessObjects";

const { Description } = DescriptionList;
export default {
  name: "BaseDetail",
  props: {
    // 基本信息
    baseInfo: {},
    // 建筑信息
    buildingInfo: {},
    // 路由信息
    queryInfo: {},
    // 权限信息
    stallseditHasRight: {},
    stallsviewHasRight: {},
    stallsnewHasRight: {},
    propertyFloorPlan: {},
    // floorPlanMedias 工程平面图
    floorPlanMedias: {},
    // floorPlanMedias 设计渲染图
    designRenderMedias: {},
    // 刷新图片函数
    refreshFacilityMedias: {}
  },
  components: {
    DescriptionList,
    Description,
    NewStalls
  },
  data() {
    return {
      // 弹窗
      showImgUrl: "",
      visibleImg: false,
      // 工程平面图照片列表
      planPicList: [],
      // 设计渲染图照片列表
      renderPicList: [],
      queryParamStaff: {},
      // 人员列表
      staffData: [],
      //表头
      staffColumns: [
        {
          title: "成员",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone"
        },
        {
          title: "邮箱",
          dataIndex: "email",
          key: "email"
        }
      ],
      // salesStatus
      salesStatusList: [
        { id: 1, value: "招商中" },
        { id: 2, value: "预定中" },
        { id: 3, value: "转租中" },
        { id: 4, value: "已预定" },
        { id: 5, value: "已签约" },
        { id: 6, value: "停止招商" }
      ],
      // 档口字段
      //档口类型
      typeList: stallsType,
      //客户状态
      tenantStatusList: [
        { id: 1, value: "预订中" },
        { id: 2, value: "已预订" },
        { id: 3, value: "签约中" },
        { id: 4, value: "已签约" },
        { id: 5, value: "改造中" },
        { id: 6, value: "待入驻" },
        { id: 7, value: "已入驻" },
        { id: 8, value: "申请退租中" },
        { id: 9, value: "退租中" },
        { id: 10, value: "已退租" },
        { id: 11, value: "已离场" }
      ],
      //水电付费模式
      utilityPrepayModeList: [
        { id: 0, value: "暂无" },
        { id: 1, value: "后付费" },
        { id: 2, value: "预付费"},
      ],
      // stallsData
      stallsData: [],
      // 搜索
      queryParamStall: {},
      stallsColumns: [
        {
          title: "档口编号",
          dataIndex: "code",
          key: "code",
          fixed: "left",
          width: 100
        },
        {
          title: "档口类型",
          dataIndex: "type",
          key: "type"
        },
        {
          title: "建筑等级",
          dataIndex: "engineeringLevel",
          key: "engineeringLevel"
        },
        {
          title: "档口电量",
          dataIndex: "actualElectricity",
          key: "actualElectricity"
        },
        {
          title: "档口排烟量",
          dataIndex: "actualExhaust",
          key: "actualExhaust"
        },
        {
          title: "档口使用面积",
          dataIndex: "usableArea",
          key: "usableArea",
          width: 120
        },
        {
          title: "燃气",
          dataIndex: "gas",
          key: "gas"
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "招商状态",
          dataIndex: "salesStatus",
          key: "salesStatus",
          scopedSlots: { customRender: "salesStatus" }
        },
        {
          title: "客户状态",
          dataIndex: "tenantStatus",
          key: "tenantStatus"
        },
        {
          title: "上下架",
          dataIndex: "bdStatus",
          key: "bdStatus",
          scopedSlots: { customRender: "bdStatus" }
        },
        {
          title: "水电付费模式",
          dataIndex: "utilityPrepayMode",
          key: "utilityPrepayMode",
          width: 140
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ],
      // 增加人员
      showAddStalls: false
    };
  },
  watch: {},
  created() {
    this.getStaffList();
    this.kitchenList();
  },
  computed: {},
  methods: {
    moment,
    // 工具
    // pushType
    imgPushType(arr) {
      if (!arr) {
        return [];
      }
      let that = this;
      let aa = [];
      arr.forEach(item => {
        let type = that.checkURLType(item);
        aa.push({
          url: item,
          fType: type
        });
      });
      return aa;
    },
    // 判断图片
    checkURLType(item) {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item)) {
        return "img";
      } else {
        return "other";
      }
    },
    // 查看
    handleDetail(val) {
      this.$router.push({
        path: "/stalls/stallsList/stallsListDetail",
        query: { stallsDetailId: val.id, stallsDetailtype: val.type }
      });
    },
    //编辑档口
    handleEdit(val) {
      this.$router.push({
        path: "/stalls/stallsList/stallsListEdit",
        query: { stallsEditId: val.id }
      });
    },
    // 获取招商状态
    getSalesStatus(id) {
      if (!id) {
        return;
      }
      let status = "";
      this.salesStatusList.forEach(item => {
        if (item.id == id) {
          status = item.value;
        }
      });
      return status;
    },
    // 删除
    //删除档口
    handleDeleteStalls(record) {
      let that = this;
      var formData = new FormData();
      formData.append("kitchenId", record.id);
      let accessToken = localStorage.getItem("token");
      let mid = sessionStorage.getItem("midKey");
      Modal.confirm({
        title: "确定要删除该信息吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          fetch(  that.$baseUrl + "/kitchen/remove", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "x-requested-with": JSON.stringify({
                accessKey: accessToken,
                mid: mid
              })
            },
            withCredentials: true,
            body: formData
          }).then(res => {
            if (res.status == 200) {
              that.$message.success("档口已删除！");
              that.kitchenList();
            } else {
              that.$message.error(`${res.status}`);
            }
          });
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {}
      });
    },
    // 取消编辑
    cancelAddStalls() {
      this.kitchenList();
      this.showAddStalls = false;
    },
    // 上传
    uploadFloorPlanMedias({ fileList }) {
      fileList.forEach((item, index) => {
        if (item.status == "error") {
          fileList.splice(index, 1);
          this.$message.error("上传失败！");
          return;
        }
      });
      this.refreshFacilityMedias();
    },
    // 返回日期格式
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    //营业时间的展示
    showTimeStyle(time) {
      return time ? moment(time).format("HH:mm") : "";
    },
    // 查看照片
    viewImg(e) {
      this.showImgUrl = e.target.src || "";
      this.visibleImg = true;
    },
    //查找员工列表
    onSearchStaff() {
      this.getStaffList();
    },
    // 获取员工列表
    getStaffList(current, pageSize) {
      axios({
        url: "/facility/" + this.queryInfo.id + "/staff",
        method: "GET",
        withCredentials: true
      }).then(res => {
        if (res.data.rows) {
          this.staffData = res.data.rows;
        }
      });
    },
    // 搜索档口列表
    onSearchStall() {
      this.kitchenList();
    },
    // 厨房初始化
    kitchenList() {
      axios({
        url: "kitchen/facility/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: 1000, // 此列表不需要分页了
          page: 1,
          facilityId: this.queryInfo.id,
          codeOrName: this.queryParamStall.codeOrName
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.bdStatus = item.bdStatus == 1 ? "可招商" : "不可招商";
            this.typeList.forEach(info => {
              //档口类型
              if (info.id == item.type) {
                item.type = info.name;
              }
            });
            this.tenantStatusList.forEach(info => {
              //客户状态
              if (info.id == item.tenantStatus) {
                item.tenantStatus = info.value;
              }
            });
            if (item.utilityPrepayMode != null && item.utilityPrepayMode != undefined) {
              this.utilityPrepayModeList.forEach(info => {
                //水电付费模式
                if (info.id == item.utilityPrepayMode) {
                  item.utilityPrepayMode = info.value;
                }
              })
            }
            else {
              item.utilityPrepayMode = '暂无';
            }
            
          });
          this.stallsData = res.data.rows;
        }
      });
    },
    // 增加档口
    //新增档口
    addStalls() {
      this.showAddStalls = true;
    }
  },
  filters: {
    // 营业执照范围
    showbizLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "餐饮企业管理";
          break;
        case "2":
          return "会务服务";
          break;
        case "3":
          return "展览展示服务";
          break;
        case "4":
          return "餐饮服务";
          break;
        case "5":
          return "清洁服务";
          break;
        case "6":
          return "礼仪服务";
          break;
      }
    },
    // 食品经营许可证范围
    showCateringLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "热食类食品制售";
          break;
        case "2":
          return "冷食类食品制售";
          break;
        case "3":
          return "生食类食品制售（即生食类食品）";
          break;
        case "4":
          return "自制饮品";
          break;
        case "5":
          return "半成品";
          break;
        case "6":
          return "预包装";
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.ant-card-wider-padding .ant-card-body {
  padding: 0 !important;
  background-color: red;
}
.p18 {
  padding: 18px;
}
.mt18 {
  margin-top: 18px;
}
.mb18 {
  margin-bottom: 18px;
}
.ml18 {
  margin-left: 18px;
}
.fbt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
