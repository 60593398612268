<template>
  <div style="background-color:#f0f2f5;padding:0;">
    <a-card :bordered="false" class="p18">
      <DescriptionList title="证照信息" size="large">
        <div style="margin-top: 16px">
          <Description term="门店主体名称"
            ><label>{{ baseInfo.entity }}</label></Description
          >
          <Description term="营业期限-开始日期"
            ><label>{{
              this.changeTimeStyle(baseInfo.bizLicenseStartDateLg)
            }}</label></Description
          >
          <Description term="营业期限-结束日期"
            ><label>{{
              this.changeTimeStyle(baseInfo.bizLicenseEndDateLg)
            }}</label></Description
          >
          <Description term="统一社会信用代码"
            ><label>{{ baseInfo.socialCode }}</label></Description
          >
          <Description term="营业执照经营范围"
            ><label
              v-for="(item, index) in baseInfo.bizLicenseScopeIdList"
              :key="index"
              style="margin-right:10px"
              >{{ item | showbizLicenseScopeIdList }}</label
            ></Description
          >
        </div>
      </DescriptionList>
      <a-divider />
      <DescriptionList size="large" class="marginB24">
        <Description term="FDA食药监是否通过"
          ><label>{{
            baseInfo.fdaStatus == "N" ? "否" : "是"
          }}</label></Description
        >
        <Description term="食品经营许可证-开始日期"
          ><label>{{
            this.changeTimeStyle(baseInfo.foodCertificateStartDateLg)
          }}</label></Description
        >
        <Description term="食品经营许可证-结束日期"
          ><label>{{
            this.changeTimeStyle(baseInfo.foodCertificateEndDateLg)
          }}</label></Description
        >
        <Description term="食品经营许可证编码"
          ><label>{{ baseInfo.foodCertificate }}</label></Description
        >
        <Description term="食品经营许可证范围"
          ><label
            v-for="(item, index) in baseInfo.cateringLicenseScopeIdList"
            :key="index"
            style="margin-right:10px"
            >{{ item | showCateringLicenseScopeIdList }}</label
          ></Description
        >
      </DescriptionList>
      <a-divider />
      <DescriptionList size="large" class="marginB24">
        <Description term="FSB消防验收通过"
          ><label>{{
            baseInfo.fsbStatus == "N" ? "否" : "是"
          }}</label></Description
        >
        <Description term="消防许可通过日期"
          ><label>{{
            this.changeTimeStyle(baseInfo.fsbPassDateLg)
          }}</label></Description
        >
        <Description term="是否有大消防证"
          ><label>{{
            this.changeTimeStyle(baseInfo.fireCertificate)
          }}</label></Description
        >
        <Description term="消防许可证编号"
          ><label>{{ baseInfo.fsbCode }}</label></Description
        >
      </DescriptionList>
      <a-divider />
      <DescriptionList size="large" class="marginB24">
        <Description term="是否有环评备案"
          ><label>{{
            baseInfo.environmentAssessment == "N" ? "否" : "是"
          }}</label></Description
        >
        <Description term="是否有红头文件"
          ><label>{{
            baseInfo.governmentOfficialLetter == "N" ? "否" : "是"
          }}</label></Description
        >
      </DescriptionList>
      <a-divider />
      <DescriptionList size="large">
        <a-card class="p18" :bordered="false">
          <a-form layout="inline">
            <a-row :gutter="30" style="line-height: 4">
              <a-col :md="24" :sm="24">
                <a-form-item label="营业执照图片">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in bizLicenseMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in bizLicenseMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="食品经营许可证图片">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in foodCertificateMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in foodCertificateMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="食药监平面图图片">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in fdaFloorPlanMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in fdaFloorPlanMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="竣工验收备案">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in completionMediaUrls.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in completionMediaUrls.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="消防验收意见书(备案)：">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in fsbMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in fsbMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="消防安全检查合格证：">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in fsbSecurityCheckPhoto.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in fsbSecurityCheckPhoto.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="其他资料：">
                  <div class="imgBox">
                    <img
                      v-for="(item, index) in otherMediaUrls.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      style="width:6em;height:6em;"
                      slot="cover"
                      alt="example"
                      @click="viewImg($event)"
                      :src="item.url"
                    />
                  </div>
                  <div class="otherBox">
                    <a
                      v-for="(item, index) in otherMediaUrls.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      target="_blank"
                      :href="item.url"
                      :download="item.url"
                      >[附件{{ index + 1 }}]</a
                    >
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
    </a-card>
    <!-- 弹窗 -->
    <a-modal :width="1000" v-model="visibleImg" title="图片详情" :footer="null">
      <img style="width:100%;height:100%;" :src="showImgUrl" />
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;

export default {
  name: "LicenceDetail",
  props: {
    // 权限信息
    // hasRight:{},
    // 基本信息
    baseInfo: {},
    // foodCertificateMedias 食品经营许可证图片
    foodCertificateMedias: {},
    // 营业执照图片
    bizLicenseMedias: {},
    // 消防许可证图片
    fsbMedias: {},
    // 食药监平面图图片
    fdaFloorPlanMedias: {},
    // completionMediaUrls
    completionMediaUrls: {},
    // fsbSecurityCheckPhoto
    fsbSecurityCheckPhoto: {},
    // /otherMediaUrls
    otherMediaUrls: {}
  },
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      // 弹窗
      showImgUrl: "",
      visibleImg: false
    };
  },
  watch: {},
  filters: {
    // 营业执照范围
    showbizLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "餐饮企业管理";
          break;
        case "2":
          return "会务服务";
          break;
        case "3":
          return "展览展示服务";
          break;
        case "4":
          return "餐饮服务";
          break;
        case "5":
          return "清洁服务";
          break;
        case "6":
          return "礼仪服务";
          break;
      }
    },
    // 食品经营许可证范围
    showCateringLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "热食类食品制售";
          break;
        case "2":
          return "冷食类食品制售";
          break;
        case "3":
          return "生食类食品制售（即生食类食品）";
          break;
        case "4":
          return "自制饮品";
          break;
        case "5":
          return "半成品";
          break;
        case "6":
          return "预包装";
          break;
      }
    }
  },
  created() {},
  computed: {},
  methods: {
    moment,
    // 查看照片
    viewImg(e) {
      this.showImgUrl = e.target.src || "";
      this.visibleImg = true;
    },
    // 返回日期格式
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
</style>
